import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function BlogRoll() {
  const data = useStaticQuery(graphql`
    query BlogRoll {
      allStrapiArticle(sort: {order: DESC, fields: published_at}) {
        edges {
          node {
            id
            title
            slug
            image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            published_at(formatString: "M/DD/YYYY")
            author {
              name
            }
          }
        }
      }
    }
  `)

  const articles = data.allStrapiArticle.edges

  return (
    <>
      <div className="md:space-y-6 space-y-3 ">
        {articles.map(article => (

          <div key={article.node.id} className="">
            <div className="bg-gray-50 shadow-lg flex flex-row  rounded-r-xl">

              <Link to={'/blog/' + article.node.slug}>
                <GatsbyImage image={article.node.image.localFile.childImageSharp.gatsbyImageData} alt={`Picture for ${article.node.title} article`} className="object-cover rounded-l-xl h-[10rem] w-[10rem]" />
              </Link>


              <div className=" flex items-center px-2 py-2 md:px-4">
                <div className="">

                  <Link to={'/blog/' + article.node.slug} >
                    <h1 className="md:text-3xl text-xl font-semibold hover:text-rose-400 whitespace-normal">
                      {article.node.title}
                    </h1>
                  </Link>

                  <h1 className="text-sm pt-2">By, <span className="font-semibold">{article.node.author.name}</span> | {article.node.published_at} </h1>
                </div>

              </div>
            </div>
          </div>

        ))}

      </div>

    </>
  )

}