import * as React from 'react'
import BlogRoll from '../components/BlogRoll';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import Seo from '../components/seo';

const BlogPage = () => {
    return(
        <div>
            <Nav />
            <Seo title="Blog" />
            <div className="bg-gray-100 min-h-screen md:pb-16 pb-2">

                <div className="flex flex-col justify-center items-center">
                    <div className="md:px-10 px-2 md:pt-16 pt-10 text-center">
                        <h1 className="text-6xl font-semibold -ml-2 text-gray-900">Latest Articles</h1>
                        
                        
                        
                        
                    </div>

                    <BlogRoll />

                </div>
            </div>

            <Footer />
        </div>
    )
};

export default BlogPage

